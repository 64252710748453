import Rollbar from 'rollbar'
import { fallback } from '$lib/rollbar.js'
import { PUBLIC_CASCA_ENV, PUBLIC_ROLLBAR_TOKEN } from '$env/static/public'
import { version } from '$app/environment'

export default (
  PUBLIC_ROLLBAR_TOKEN
    ? new Rollbar({
      accessToken: PUBLIC_ROLLBAR_TOKEN,
      autoInstrument: true,
      captureEmail: true,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: PUBLIC_CASCA_ENV,
        client: {
          javascript: {
            code_version: version,
            source_map_enabled: true
          }
        }
      }
    })
  : fallback
)
