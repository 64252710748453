import rollbar from '$lib/rollbar.client.js'
import { createPayloadFromSession } from '$lib/rollbar.js'
import { get } from 'svelte/store'
import { session } from '$lib/session.js'

export function handleError ({ error, event, message }) {
  const payload = { ...createPayloadFromSession(get(session)), event }

  rollbar.error(error.message, error, payload, err => {
    if (!err) return

    console.log('error while pushing to Rollbar, ignoring', err)
  })

  return { message }
}
