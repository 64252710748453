import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166')
];

export const server_loads = [];

export const dictionary = {
		"/(site)": [~29,[2]],
		"/admin": [37,[3]],
		"/admin/adiante/[id]/edit": [38,[3]],
		"/admin/admin_users/[id]/edit": [39,[3]],
		"/admin/billing-previews": [40,[3]],
		"/admin/cceal-ccee-energy-amounts": [41,[3]],
		"/admin/ccee-contracts": [42,[3]],
		"/admin/ccee-contracts/[slug]": [43,[3,4]],
		"/admin/ccee-settlements": [44,[3]],
		"/admin/cdes": [45,[3]],
		"/admin/companies": [46,[3]],
		"/admin/companies/new": [67,[3]],
		"/admin/companies/[id]": [47,[3,5]],
		"/admin/companies/[id]/consumer-units": [48,[3,5,6]],
		"/admin/companies/[id]/consumer-units/new": [58,[3,5,6]],
		"/admin/companies/[id]/consumer-units/[slug]": [49,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/edit": [50,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/fattureweb-distributor-credentials/edit": [51,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/financial-obligations/new": [52,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/migration-tasks": [53,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings": [54,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/new": [56,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/monthly-savings/[monthlySavingId]/edit": [55,[3,5,6,7]],
		"/admin/companies/[id]/consumer-units/[slug]/viability-studies/new": [57,[3,5,6,7]],
		"/admin/companies/[id]/contracts/new": [59,[3,5]],
		"/admin/companies/[id]/edit": [60,[3,5]],
		"/admin/companies/[id]/financial-obligations/new": [61,[3,5]],
		"/admin/companies/[id]/managerial-reports": [62,[3,5]],
		"/admin/companies/[id]/proposals/new": [63,[3,5]],
		"/admin/companies/[id]/saving-reports": [64,[3,5]],
		"/admin/companies/[id]/saving-reports/new": [65,[3,5]],
		"/admin/companies/[id]/users": [66,[3,5,8]],
		"/admin/contract-terms/[id]/edit": [68,[3,9]],
		"/admin/contract-thresholds": [69,[3]],
		"/admin/contract-thresholds/[contractId]/[referenceMonth]": [70,[3,10]],
		"/admin/contracts": [71,[3]],
		"/admin/contracts/[id]": [72,[3,11]],
		"/admin/contracts/[id]/edit": [73,[3,11]],
		"/admin/contracts/[id]/terms/new": [74,[3,11]],
		"/admin/devecs": [75,[3]],
		"/admin/distributor-companies": [76,[3]],
		"/admin/distributor-companies/[id]": [77,[3,12]],
		"/admin/distributor-companies/[id]/edit": [78,[3,12]],
		"/admin/fattureweb-interactions": [79,[3]],
		"/admin/fattureweb-interactions/[id]": [80,[3,13]],
		"/admin/financial-calendar-events": [81,[3]],
		"/admin/financial-obligations": [82,[3]],
		"/admin/financial-obligations/new": [85,[3]],
		"/admin/financial-obligations/[id]": [83,[3,14]],
		"/admin/financial-obligations/[id]/edit": [84,[3,14]],
		"/admin/hourly-average-co2-emission-factors": [86,[3]],
		"/admin/hourly-plds": [87,[3]],
		"/admin/imports": [88,[3]],
		"/admin/imports/new": [90,[3]],
		"/admin/imports/[id]": [89,[3,15]],
		"/admin/ipca-indices": [91,[3]],
		"/admin/leads": [92,[3]],
		"/admin/login": [93,[3,16]],
		"/admin/login/password-reset/new": [95,[3,16]],
		"/admin/login/password-reset/[token]": [94,[3,16]],
		"/admin/logout": [96,[3]],
		"/admin/mail-recipients/[id]/mail-subscriptions": [97,[3,17]],
		"/admin/measurements": [98,[3]],
		"/admin/monthly-average-co2-emission-factors": [99,[3]],
		"/admin/monthly-consumption": [100,[3,18]],
		"/admin/monthly-plds": [101,[3]],
		"/admin/operations": [102,[3]],
		"/admin/operations/new": [105,[3]],
		"/admin/operations/[id]": [103,[3,19]],
		"/admin/operations/[id]/edit": [104,[3,19]],
		"/admin/optimal-demand-simulations/new": [106,[3]],
		"/admin/optimal-tariff-modality-simulations/new": [107,[3]],
		"/admin/partners": [108,[3]],
		"/admin/proinfa-ccee-energy-amounts": [109,[3]],
		"/admin/proposals": [110,[3]],
		"/admin/proposals/[id]": [111,[3,20]],
		"/admin/renewable-energy-certificates": [112,[3]],
		"/admin/sendgrid-events": [113,[3]],
		"/admin/sendgrid-events/[id]": [114,[3,21]],
		"/admin/state-taxes": [115,[3]],
		"/admin/status-aggregated-measurements": [116,[3]],
		"/admin/tariff-flags-terms": [119,[3]],
		"/admin/tariff-flags": [117,[3]],
		"/admin/tariff-flags/new": [118,[3]],
		"/admin/unauthorized": [120,[3]],
		"/admin/users": [121,[3]],
		"/admin/users/[id]": [122,[3,22]],
		"/admin/users/[id]/accesses": [123,[3,22]],
		"/admin/viability-studies": [124,[3]],
		"/admin/viability-studies/new": [128,[3]],
		"/admin/viability-studies/[id]": [125,[3,23]],
		"/admin/viability-studies/[id]/document": [126],
		"/admin/viability-studies/[id]/new": [127,[3,23]],
		"/agrishow": [129],
		"/cadastro/continuar/[token]/[userId]": [130,[24]],
		"/cadastro/empresa/completo": [131,[24,25]],
		"/cadastro/empresa/editar": [132,[24,25]],
		"/cadastro/empresa/endereco/editar": [133,[24,25]],
		"/cadastro/empresa/representantes": [134,[24,25]],
		"/cadastro/empresa/representantes/novo": [136,[24,25]],
		"/cadastro/empresa/representantes/[id]/editar": [135,[24,25]],
		"/cadastro/empresa/unidades-consumidoras": [137,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/nova": [140,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/editar": [138,[24,25]],
		"/cadastro/empresa/unidades-consumidoras/[id]/endereco/editar": [139,[24,25]],
		"/cadastro/empresa/usuarios": [141,[24,25]],
		"/cadastro/empresa/usuarios/novo": [142,[24,25]],
		"/cadastro/obrigado": [143,[24]],
		"/cadastro/uc/fatura/email": [144,[24]],
		"/cadastro/uc/fatura/nova": [145,[24]],
		"/cadastro/uc/informe-de-dados": [146,[24]],
		"/cadastro/uc/nova": [147,[24]],
		"/cadastro/uc/unidades": [148,[24]],
		"/cadastro/usuario/novo": [149,[24]],
		"/(site)/contato": [30,[2]],
		"/empresa-b": [~150],
		"/(site)/mercado-livre-de-energia": [31,[2]],
		"/painel/(auth)/login": [151,[26,27]],
		"/painel/logout": [165,[26]],
		"/painel/(auth)/senha/definida": [152,[26,27]],
		"/painel/(auth)/senha/nova": [153,[26,27]],
		"/painel/(auth)/senha/nova/[token]": [154,[26,27]],
		"/painel/[companyId]": [155,[26,28]],
		"/painel/[companyId]/bi": [156,[26,28]],
		"/painel/[companyId]/consumo": [157,[26,28]],
		"/painel/[companyId]/economia": [158,[26,28]],
		"/painel/[companyId]/mercado-livre": [159,[26,28]],
		"/painel/[companyId]/meus-dados": [160,[26,28]],
		"/painel/[companyId]/migracao": [161,[26,28]],
		"/painel/[companyId]/minha-empresa": [162,[26,28]],
		"/painel/[companyId]/pagamentos": [163,[26,28]],
		"/painel/[companyId]/sustentabilidade": [164,[26,28]],
		"/(site)/parcerias": [32,[2]],
		"/(site)/sobre-nos": [33,[2]],
		"/(site)/solucoes": [34,[2]],
		"/(site)/sustentabilidade": [35,[2]],
		"/(site)/termo-de-adesao": [~36,[2]],
		"/w": [166]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';