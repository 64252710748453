const debug = method(console.debug)
const error = method(console.error)
const log = method(console.log)

export const fallback = {
  critical: error,
  debug,
  error,
  info: log,
  log: error,
  warning: log
}

function method (stub) {
  return function (...rollbarCallArgs) {
    const stubArgs = []
    let callback

    for (const arg of rollbarCallArgs) {
      if (typeof arg === 'function' && !callback) callback = arg
      else stubArgs.push(arg)
    }

    stub(...stubArgs)

    if (callback) callback()
  }
}

export function createPayloadFromSession (session = {}) {
  const { id, email } = session.adminUser || session.user || {}
  const payload = id ? { person: { id, email } } : {}

  if (session.adminUser?.email && session.user?.email) {
    payload.userEmail = session.user.email
  }

  return payload
}
